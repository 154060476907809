import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactThanksPage = ({ data }) => {
  const html = get(
    data,
    "contentfulContactPage.successMessage.childMarkdownRemark.html"
  )

  return (
    <Layout>
      {/* Fixed SEO title */}
      <SEO title="Bedankt" />
      <h1>Bedankt</h1>
      <div className="mb-8 html" dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactSuccessQuery {
    contentfulContactPage(contentful_id: { eq: "2ArmRlE6YbiUgJkzsSMp7d" }) {
      successMessage {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default ContactThanksPage
